<script lang="ts">
    import type { ColorPalette } from '@/types'
    export let classes: string = "";
    export let disabled: boolean = false;
    export let color: ColorPalette = "gray-10";
    export let backgroundColor: ColorPalette = "gray-50";
    export let style: string = "";
    // export let width = "32px";
    // export let height = width;
</script>

<button
    on:mouseenter
    on:mouseleave
    on:click
    class="round round-button {classes}"
    {disabled}
    style="background-color: var(--{backgroundColor}); color: var(--{color}); {style}"
>
    <slot />
</button>

<style>
    button.green-button {
        background-color: var(--green-40);
        color: var(--gray-80);
    }

    button[disabled] {
        cursor: initial;
        color: var(--disabled-sort-button-color);
    }

    button.open {
        background-color: var(--yellow-30);
    }
    button {
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 24px;
        border: none;
        padding-left: 8px;
        padding-right: 8px;
        /* text-align: center; */
        justify-content: center;
        /* margin-left: 4px; */
        /* margin-right: 0; */
        font-size: 16px;
        /* color: var(--gray-10);
        background-color: var(--gray-50); */

        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;  
    }
    @media only screen and (max-width: 900px) {
        button {
            height: 36px;
            font-size: 18px;
            font-weight: 200;
            padding: 12px;
        }
    }
</style>
