<script lang="ts">
    // import type { GalleryExport } from "@/types";
    import { onMount } from "svelte";
    import Modal from "./Modal.svelte";
    import { downloadCanvas, downloadJSON } from "@/utils";
    import { exportBeforeAfter, drawHistoryStore } from "@/stores/drawHistory";
    import { mainCanvas, activeCanvas } from "@/stores/canvases";
    import { postJSON } from "@/utils/network";
    import config from "@/config";
    import { get } from "svelte/store";
    export let show = false;
    export function toggle() {
        show = !show;
    }
    // let artworkName = ''
    // let artistName = ''

    // Return masks accounting for stale localStorage which may have a null value
    function getMasks() {
        return $drawHistoryStore.masks || [];
    }

    let beforeAfter: HTMLCanvasElement | null = null;
    $: if (show == true && getMasks().length > 0) {
        exportBeforeAfter().then((_beforeAfter) => {
            beforeAfter = _beforeAfter;
        });
    }
    $: if (getMasks().length == 0) {
        beforeAfter = null;
    }

    function dec2hex(dec: number): string {
        return dec.toString(16).padStart(2, "0");
    }
    export function randomId(len: number = 20): string {
        const arr = new Uint8Array(len / 2);
        window.crypto.getRandomValues(arr);
        return Array.from(arr, dec2hex).join("");
    }

    async function exportForGallery() {
        const artistName = prompt("Enter your name for submission");
        const imageBase64 = $mainCanvas
            .getCanvas()
            .toDataURL("image/jpeg", 0.9);
        await postJSON(`${config.apiURL}/submit_artwork`, {
            artistName,
            imageBase64,
            masks: getMasks(),
        });
        alert("Submitted!");
        // show = false;
    }
</script>

<Modal bind:show>
    <div class="select-image-modal-trigger" slot="trigger" let:open>
        <slot name="trigger" {open} />
    </div>
    <div class="content" slot="body">
        <button
            on:click={() =>
                downloadCanvas(
                    get(mainCanvas).getCanvas(),
                    "prosepainter-image.png"
                )}
        >
            <p>Download Image</p>
        </button>

        {#if getMasks().length}
            <button on:click={exportForGallery}>
                <p>Share to Gallery</p>
            </button>
            <!-- <form on:submit|preventDefault={exportForGallery}>
                <p>Share to Gallery</p>
                <input
                    on:keypress={(e) => /[A-Za-z0-9 _-]/i.test(e.key)}
                    bind:value={artworkName}
                    placeholder="artwork name"
                    type="text"
                    minlength={4}
                    required={true}
                />
                <input
                    on:keypress={(e) => /[A-Za-z0-9 _-]/i.test(e.key)}
                    bind:value={artistName}
                    minlength={4}
                    placeholder="artist name"
                    required={true}
                    type="text"
                />
                <input type="submit" value="Submit">
            </form> -->
        {/if}

        {#if beforeAfter}
            <button
                id="downloadBeforeAfterButton"
                on:click={async (e) =>
                    downloadCanvas(beforeAfter, "before-after-prose.jpg")}
            >
                <p>Download Before & After</p>
                <img
                    id="beforeafterimg"
                    src={beforeAfter.toDataURL()}
                    alt="before&after"
                />
            </button>
        {/if}
    </div>
</Modal>

<style>
    button {
        margin: 8px;
    }
    form {
        margin: 8px;
        text-align: center;
    }
    .content {
        display: flex;
        flex-direction: column;
    }
    #beforeafterimg {
        max-width: 80%;
    }
</style>
