<script lang="ts">
    import InfoModal from "@/components/InfoModal.svelte";
    import CreateModal from "@/components/CreateModal.svelte";

    import downloadUrl from "@/assets/download.svg";
    import zoomInURL from "@/assets/zoom-in.svg";
    import zoomOutURL from "@/assets/zoom-out.svg";
    import fitImageURL from "@/assets/minimize.svg";
    import undoURL from "@/assets/icon-undo.svg";
    import redoURL from "@/assets/icon-redo.svg";
    import createURL from "@/assets/plus.svg";
    import backURL from "@/assets/arrow-left.svg";
    import infoURL from "@/assets/info.svg";
    // import beforeAfterUrl from "@/assets/before-after.svg";
    // import { downloadCanvas } from "@/utils";
    // import { exportImage, drawHistoryStore } from "@/stores/drawHistory";
    import { mainCanvas, activeCanvas } from "@/stores/canvases";
    import { zoom } from "@/stores/ui";
    import { Mode } from "@/types";
    import { mode, isMobile } from "@/stores/ui";
    import { fitImage } from "@/stores/viewer";
    import Button from "./Button.svelte";
    import * as optEvents from "@/optimizeEvents";
    import DownloadModal from "./DownloadModal.svelte";

    // import Toggle from "./Toggle.svelte";
    export let type:
        | "info"
        | "create"
        | "download"
        | "zoom"
        | "undo"
        | "home"
        | "start";
    export let style = "";
    let infoModal: InfoModal;
    let createModal: CreateModal;
    let showCreate = false
</script>

<svelte:body class:isMobile={$isMobile} />
<InfoModal bind:this={infoModal} on:tryit={() => showCreate=true}/>
<CreateModal bind:this={createModal} bind:show={showCreate}/>
{#if type == "create"}
    <button {style} on:click={() => createModal.toggle()}>
        <img src={createURL} alt="add" />
    </button>
{:else if type == "info"}
    <button on:click={() => infoModal.toggle()} style="padding:4px;">
        <img src={infoURL} alt="info" />
    </button>
{:else if type == "home"}
    <button {style} on:click={() => ($mode = Mode.MobileHomeScreen)}>
        <img src={backURL} alt="back" />
    </button>
{:else if type == "start"}
    <Button
        style="margin:4px;font-size:20px;"
        backgroundColor={"green-40"}
        color={"gray-90"}
        on:click={() => optEvents.start()}>Start</Button
    >
{:else if type == "download"}
    <DownloadModal let:open >
        <div slot="trigger">
            <button {style} on:click={open}>
                <img src={downloadUrl} alt="download" />
            </button>
        </div>
    </DownloadModal>
{:else if type == "undo"}
    <button
        {style}
        on:click={(e) => $activeCanvas.undo()}
        class="left-group-button"
    >
        <img src={undoURL} alt="undo" />
    </button>
    <button
        {style}
        on:click={(e) => $activeCanvas.redo()}
        class="right-group-button"
    >
        <img src={redoURL} alt="redo" />
    </button>
{:else if type == "zoom"}
    <button {style} on:click={(e) => ($zoom *= 0.8)} class='left-group-button'>
        <img src={zoomOutURL} alt="zoom-out" />
    </button>
    <button {style} on:click={(e) => ($zoom *= 1.2)} class='center-group-button'>
        <img src={zoomInURL} alt="zoom-in" />
    </button>
    <button {style} on:click={(e) => fitImage()} class='right-group-button'>
        <img src={fitImageURL} alt="fit" />
    </button>
{/if}

<!--         
            <button
                id="downloadBeforeAfterButton"
                on:click={async (e) =>
                    downloadCanvas(
                        await exportImage(),
                        "before-after-prose.jpg"
                    )}
            >
                <img src={beforeAfterUrl} alt="download" />
            </button>
        
       
        
    </div>
</div> -->
<style>
    button img {
        width: 100%;
        height: 100%;
    }
    /* #zoom-buttons {
        display: flex;
        flex-direction: column;
    } */
    button {
        background-color: var(--gray-20);
        border-radius: 50%;
        width: 36px;
        height: 36px;
        margin: 4px;
        padding: 8px;
        border: none;
    }
    .left-group-button {
        margin-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .right-group-button {
        margin-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .center-group-button {
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 0px;
    }
    @media only screen and (max-width: 900px) {
        button {
            width: 36px;
            height: 36px;
        }
    }
</style>
