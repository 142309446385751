// A file to store the history of the edits to a canvas.
import { get } from 'svelte/store'
import { DEFAULT_IMAGE } from '../constants'
import { canvasToImage, loadImage, thumbnailCanvas } from '@/utils'
import { mainCanvas, maskCanvas } from '@/stores/canvases'
import { prompt } from '@/stores/ui'
import { localStorageWritable } from '@/stores/storeUtils'

interface DrawHistory {
    startImage: string
    masks: Array<{ imageBase64: string, prompt: string }>
}

export const drawHistoryStore = localStorageWritable<DrawHistory>('drawHistory', {
    startImage: '',
    masks: []
})

export function recordOptimizationHistory() {
    console.log('Accepted.')
    const smallCanvas = thumbnailCanvas(get(maskCanvas).getCanvas(), 256)
    const imageBase64 = smallCanvas.toDataURL('image/webp');
    drawHistoryStore.update(($drawHistoryStore) => {
        $drawHistoryStore.masks = $drawHistoryStore.masks || []
        $drawHistoryStore.masks.push({ imageBase64, prompt: get(prompt) })
        // Limit the total, taking the previous 12.
        $drawHistoryStore.masks = $drawHistoryStore.masks.slice(-12)
        console.log('Draw history length:', $drawHistoryStore.masks.length);
        return $drawHistoryStore
    })
}

// export function setHasOptimized() {
//     console.log('Accepted.')
//     drawHistoryStore.update((_drawHistoryStore) => ({
//         ..._drawHistoryStore,
//         hasOptimized: true,
//     }))
// }

export function reset() {
    // Called when draw canvas is set to a new image.
    console.log('draw history reset')
    drawHistoryStore.update((_drawHistoryStore) => ({
        startImage: get(mainCanvas).getCanvas().toDataURL('image/jpeg', 90),
        masks: []
    }))
}

export async function exportBeforeAfter(): Promise<HTMLCanvasElement> {
    const finalCanvas = get(mainCanvas).getCanvas()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = (finalCanvas.width * 2)
    canvas.height = finalCanvas.height
    // The start image will be an empty string on the first load.
    const startImageUrl = get(drawHistoryStore).startImage || DEFAULT_IMAGE
    const startImage = await loadImage(startImageUrl)
    ctx.drawImage(startImage, 0, 0)
    ctx.drawImage(finalCanvas, finalCanvas.width, 0)
    return canvas
}