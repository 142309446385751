<script lang="ts">
    import { mode, optimizationResults, selectedOptIdx } from "@/stores/ui";
    import { Mode } from "@/types";
    import * as optEvents from "@/optimizeEvents";
    import Slider from "@/components/Slider.svelte";
    import Button from "@/components/Button.svelte";
    $: results = $optimizationResults;
</script>

{#if $mode == Mode.Optimizing}
    <div class="outer" style="color:var(--gray-20);">
        <div class="row">
            <Button on:click={() => optEvents.pause()}>
                <p>Stop</p>
            </Button>
        </div>
        <div class="row">
            {#if results}
                <p>{results.images.length} / {results.num_iterations}</p>
            {:else}
                <p>Waiting to start...</p>
            {/if}
        </div>
    </div>
{:else if $mode == Mode.PausedOptimizing}
    <div class="outer">
        <div class="row">
            <Button
                style="flex-grow:1;margin-left:4px;"
                on:click={() => optEvents.discard()}
            >
                <p>Discard</p>
            </Button>
            <Button
                style="flex-grow:1;margin-right:4px;"
                on:click={() => optEvents.accept()}
            >
                <p>Accept</p>
            </Button>
        </div>
        <div class="row">
            {#if results}
                <Slider
                    name="step"
                    min={0}
                    max={results.images.length - 1}
                    step={1}
                    bind:val={$selectedOptIdx}
                />
            {/if}
        </div>
    </div>
{/if}

<style>
    .outer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
    }
    .row {
        flex-direction: row;
        justify-content: center;
        display: flex;
        flex-grow: 1;
    }
</style>
