<script lang="ts">
  import { onMount } from "svelte";
  import InfiniteViewer from "@/drawing/svelte-infinite-viewer";
  import Droppable from "@/components/Droppable.svelte";
  import Canvases from "@/components/Canvases.svelte";
  import {
    isMobile,
    userQueuePosition,
    zoom,
    innerWidth,
    innerHeight,
  } from "@/stores/ui";
  import { viewer, fitImage } from "@/stores/viewer";
  import {
    canvasSize,
    setImageByURL,
    setImageByFile,
    canvasEvents,
    activeCanvas,
  } from "@/stores/canvases";
  import ControlBarDesktop from "@/components/ControlBar.svelte";
  import ControlBarMobile from "@/components/ControlBarMobile.svelte";
  import "event-target-polyfill";
  import MobileHeader from "@/components/MobileHeader.svelte";
  import DesktopCanvasButtons from "@/components/DesktopCanvasButtons.svelte";

  let getInstance;
  let cursorX = 0;
  let cursorY = 0;
  let zoomOffsetX: number | string = "50%";
  let zoomOffsetY: number | string = "50%";
  const urlSearchParams = new URLSearchParams(window.location.search);

  $: if ($viewer && $zoom) {
    $viewer.setZoom($zoom);
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.code === "KeyZ" && (e.metaKey === true || e.ctrlKey === true)) {
      if ($activeCanvas && e.shiftKey) {
        $activeCanvas.redo();
      } else {
        $activeCanvas.undo();
      }
    }
  }

  function onDrop(e) {
    const files: File[] = Array.from(e.dataTransfer.files);
    const imageFiles: File[] = files.filter((f) => f.type.startsWith("image"));
    if (imageFiles.length) {
      setImageByFile(imageFiles[0]);
    }
  }

  function onBodyMouseMove(event) {
    cursorX = event.clientX;
    cursorY = event.clientY;
    zoomOffsetX = "50%";
    zoomOffsetY = "50%";
  }

  onMount(async () => {
    $viewer = getInstance();
    $viewer.on("pinch", (e) => {
      // On mobile there is no cursor.
      if (cursorX && cursorY) {
        zoomOffsetX = cursorX;
        zoomOffsetY = cursorY;
      }
      $zoom = e.zoom;
    });
    requestAnimationFrame(() => {
      fitImage();
    });
    canvasEvents.addEventListener("setImage", () => {
      fitImage();
    });
    if (urlSearchParams.has("image")) {
      await setImageByURL(decodeURIComponent(urlSearchParams.get("image")));
      window.history.replaceState({}, document.title, "/");
    }
    alert(
      "ProsePainter is no longer running. Please view our open source repo https://github.com/Morphogens/prosepainter and try our other tools at Artbreeder.com"
    );
    // localStorageSpace()
  });
</script>

<svelte:window
  on:keydown={onKeyDown}
  bind:innerWidth={$innerWidth}
  bind:innerHeight={$innerHeight}
/>

{#if $isMobile}
  <ControlBarMobile />
  <MobileHeader />
{:else}
  <ControlBarDesktop />
  <DesktopCanvasButtons />
{/if}
{#if $userQueuePosition > 0}
  <div id="userPoolInfo">
    <p>
      ProsePainer is currently experiencing heavy usage. You may explore the app
      but must wait to have access to the 'prose-paint.'
    </p>
    <p>You are currently in position {$userQueuePosition}.</p>
  </div>
{/if}

<div id="viewer-container" on:mousemove={onBodyMouseMove}>
  <Droppable {onDrop}>
    <InfiniteViewer
      bind:getInstance
      className="viewer"
      usePinch={true}
      maxPinchWheel={10}
      {zoomOffsetX}
      {zoomOffsetY}
    >
      <div class="viewport" style="width:{$canvasSize[0]}px">
        <Canvases />
      </div>
    </InfiniteViewer>
  </Droppable>
</div>

<style>
  :global(#maskCanvas, #optPreview, #cursorCanvas, #outlineCanvas) {
    top: 0px;
    left: 0px;
    position: absolute;
  }

  #viewer-container {
    width: 100vw;
    height: 100vh;
    background: var(--gray-80);
  }
  :global(.viewer) {
    width: 100vw;
    height: 100vh;
  }
  .viewport {
    position: relative;
  }
  :global(.hidden) {
    display: none;
  }
  #userPoolInfo {
    position: fixed;
    bottom: 75px;
    z-index: 9;
    width: 512px;
    left: calc(50vw - 256px);
    text-align: center;
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    user-select: none;
  }
</style>
