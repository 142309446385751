// Live environments serve the client and server on different domains, so we need
// a mapping.
const serverHostnameMappings = {
  'prosepainter-client.test.morphogen.io': 'prosepainter-server.test.morphogen.io',
  'prosepainter-client.staging.morphogen.io': 'prosepainter-server.staging.morphogen.io',
  'prosepainter-client.grid.morphogen.io': 'prosepainter-server.grid.morphogen.io',
  'prosepainter-client.grid-b.morphogen.io': 'prosepainter-server.grid-b.morphogen.io',
  'www.prosepainter.com': 'prosepainter-server.grid.morphogen.io'
}

const serverHostname = serverHostnameMappings[window.location.host] || window.location.host.replace(':8003', ':8004');
const proto = window.location.protocol === "https:" ? "wss:" : "ws:";

const apiURL = window.location.host.startsWith('localhost') ? 'http://localhost:8888' : 'https://www.artbreeder.com' 

export default {
  serverUrl: `${proto}//${serverHostname}/ws`,
  apiURL: `${apiURL}/prosepainter`,
  assetsUrl: 'https://prosepainter-public.b-cdn.net'
};
