<script lang="ts">
    export let name: string;
    export let val: number;
    export let min: number = 0;
    export let max: number = 1;
    export let step: number | string = "any";
</script>

<div class="slider-container">
    <!-- <p>{name}: {val}</p> -->
    <div class="textbox">
        <p style="float:left">{name}:</p>
        <p style="float:right">{val}</p>
        <div style="clear: both;"></div>
    </div>
    <input type="range"  class="slider" bind:value={val} {min} {max} {step} />
</div>

<style>
    .slider-container {
        width: 100%;
    }
    .textbox p {
        margin-bottom: 0px !important;
        font-size: 12px;
        color: var(--gray-30);
    }
    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 12px;
        border-radius: 12px;
        background: var(--gray-30);
        outline: none;
        cursor: pointer;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: var(--green-30);
        cursor: ew-resize;
    }

    .slider::-moz-range-thumb {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: var(--green-30);
        cursor: ew-resize;
    }
    .textbox {
        user-select: none;
    }
    input[type='range'] {
        margin:0px
    }
    /* p {
        text
    } */
</style>
