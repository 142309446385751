<script lang="ts">
    export let label: string
    export let group: any
    export let value: any
    export let name: string
    export let style = ''
    export let justifyContent: 'center' | 'left' = 'center'

    $: justifyContentClass = 'justify-content-' + justifyContent
</script>

<label class:selected={group === value} class={justifyContentClass} {style}>
    <input type=radio bind:group {name} {value}>
    {label}
</label>

<style>
label {
    height: 32px;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 24px;
    cursor: pointer;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    color: var(--gray-80);
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  display: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

label:not(.selected) {
    color: var(--gray-10);
}

label.selected {
    background-color: var(--gray-10);
}

.justify-content-left {
    justify-content: left;
}

.justify-content-center {
    justify-content: center;
}
</style>