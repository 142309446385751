<script lang="ts">
    import { Mode } from "@/types";
    import { isMobile, mode } from "@/stores/ui";
    import { mainCanvas, maskCanvas } from "@/stores/canvases";
    import OptimizeOptions from "./bottomControls/OptimizeOptions.svelte";
    import Button from "./Button.svelte";
    import BrushPreview from "./bottomControls/BrushPreview.svelte";
    import MaskDrawControls from "@/components/bottomControls/MaskDrawControls.svelte";
</script>

{#if $mainCanvas && $maskCanvas}
    <div class="outer-container" class:isMobile={$isMobile}>
        <div class="content-bar" class:maskDraw={$mode == Mode.MaskDraw}>
            {#if $mode == Mode.MaskDraw}
                <MaskDrawControls />
            {:else if $mode == Mode.Optimizing || $mode == Mode.PausedOptimizing}
                <OptimizeOptions />
            {:else if $mode == Mode.DirectDraw}
                <div style="margin:4px">
                    <input type="color" bind:value={$mainCanvas.strokeColor} />
                </div>
                <div style="margin:4px">
                    <BrushPreview />
                </div>
            {:else if $mode == Mode.MobileHomeScreen}
                <div class="row">
                    <Button on:click={() => ($mode = Mode.DirectDraw)}
                        >Sketch</Button
                    >
                    <Button on:click={() => ($mode = Mode.MaskDraw)}
                        >ProsePaint</Button
                    >
                </div>
            {/if}
        </div>
    </div>
{/if}

<style>
    .outer-container {
        position: fixed;
        width: 100vw;
        z-index: 1;
        display: flex;
        pointer-events: none;
        top: 8px;
        justify-content: center;
    }
    .outer-container.isMobile {
        bottom: 8px;
        top: auto;
    }
    :global(.outer-container > *) {
        pointer-events: all;
        margin-left: 4px;
        margin-right: 4px;
    }
    .content-bar {
        background-color: var(--gray-90);
        border-radius: 24px;
        padding: 4px;
        display: flex;
        position: relative;
        width: 100%;
    }
    .outer-container.isMobile .content-bar {
        background-color: inherit;
        justify-content: center;
    }
    .row {
        flex-direction: row;
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    input[type="color"] {
        cursor: pointer;
        display: flex;
        height: 32px;
        width: 150px;
        flex-shrink: 0;
    }
</style>
