import { Mode } from './types';
import { writable, get } from 'svelte/store'
import { addEventListener, messageServer } from "@/lib/socket";
import { prompt, mode, learningRate, optimizationResults, stylePrompt, numRecSteps, selectedOptIdx, selectedOptImage, userQueuePosition } from '@/stores/ui'
import { mainCanvas, maskCanvas } from '@/stores/canvases'
import { imgTob64 } from './utils';
import { recordOptimizationHistory } from '@/stores/drawHistory'
interface StartGenerationData {
    prompt: string
    stylePrompt: string,
    imageBase64: string,
    learningRate: number,
    backgroundImg: string,
    numRecSteps: number | null,
    num_iterations?: number
}

function getGenerationData(): StartGenerationData {
    return {
        num_iterations: 30,
        prompt: get(prompt),
        stylePrompt: get(stylePrompt) ?? '',
        learningRate: get(learningRate) / 1000,
        imageBase64: get(maskCanvas).canvasBase64,
        backgroundImg: get(mainCanvas).canvasBase64,
        numRecSteps: get(numRecSteps),
    }
}

function validateGenerationData(data: StartGenerationData) {
    for (const [key, value] of Object.entries(data)) {
        if (key == 'stylePrompt') {
            continue
        }
        if (key == 'numRecSteps') {
            continue
        }
        if (!value || value.length == 0) {
            throw new Error(`Empty value for: ${key}.`)
        }
    }
}

function lastOptimizationResult(): HTMLImageElement {
    const images = get(optimizationResults).images
    return images[images.length]
}

export const events: EventTarget = new EventTarget()

export function start() {
    if (!get(maskCanvas).hasAnyVisiblePixels()) {
        return alert('You must apply the prose-paint to the painting first!')
    }    
    const data = getGenerationData()
    if (data.prompt.length == 0) {
        return alert('You must enter a description!')
    }
    validateGenerationData(data)
    messageServer('start-generation', data)
    mode.set(Mode.Optimizing)
}

export function discard() {
    messageServer("stop-generation", {})
    optimizationResults.set(null)
    mode.set(Mode.MaskDraw)
}

export function accept() {
    messageServer("stop-generation", {})
    get(mainCanvas).setImage(get(selectedOptImage))
    optimizationResults.set(null)
    mode.set(Mode.MaskDraw)
    // setHasOptimized()
    recordOptimizationHistory()
    events.dispatchEvent(new CustomEvent('accepted', {}))
}

// export function upscale() {
//     const data = getGenerationData()
//     data.backgroundImg = imgTob64(get(lastOptimizationResult).image)
//     validateGenerationData(data)
//     messageServer('upscale-generation', data)
//     mode.set(Mode.Optimizing)
// }

export function pause() {
    // TODO
    // messageServer("pause-generation", {})
    messageServer("stop-generation", {})
    mode.set(Mode.PausedOptimizing)
}

export function resume() {
    // TODO
    // messageServer("resume-generation", {})
    // like start() but use lastOptimizationResult instead of main canvas
    const data = getGenerationData()
    data.backgroundImg = imgTob64(lastOptimizationResult())
    validateGenerationData(data)
    messageServer('resume-generation', data)
    mode.set(Mode.Optimizing)
}

addEventListener("message", (e) => {
    const message = JSON.parse(e.data) as { topic: string, data: any }
    console.log("MESSAGE RECEIVED!", message)
    if (message.topic == "queuePosition") {
        userQueuePosition.set(message.data.queuePosition as number)
    } else if (message.topic == "error") {
        alert(message.data)
        mode.set(Mode.PausedOptimizing)
    } else if (message.topic == 'jobProgress') {
        if (get(mode) !== Mode.Optimizing) {
            // If we get a message from the server after the user paused.
            return
        }
        const { image, step, num_iterations } = message.data
        if (image) {
            console.log("IMAGE RECEIVED!")
            const newImage = new Image()
            newImage.src = "data:text/plain;base64," + image
            optimizationResults.update(_optResults => ({
                images: (_optResults ? _optResults.images : []).concat([newImage]),
                num_iterations: num_iterations as number,
            }))
            if (step == num_iterations - 1) {
                mode.set(Mode.PausedOptimizing)
            }
            selectedOptIdx.set(step)
        } else {
            console.log("NO IMAGE RECEIVED!")
        }
    }
});
