<script lang="ts">
    import { loadJson, loadImage, imageToCanvas } from "@/utils";
    import { onMount } from "svelte";
    import config from "@/config";

    export let key: string;
    export let artistName: string;
    export let creationDate: Date;

    let maskData: Array<{ imageBase64: string; prompt: string }>;
    let maskImages: HTMLCanvasElement[];
    let hoveredIndex: null | number = null;

    $: dateString = creationDate.toDateString().split(" ").slice(1).join(" ");
    $: dataUrl = `${config.assetsUrl}/artwork/${key}.json`;
    $: imgUrl = `${config.assetsUrl}/artwork/${key}.jpg`;

    async function updateImages() {
        maskData = await loadJson(dataUrl);
        console.time("masks");
        maskImages = await Promise.all(
            maskData.map((m) => convertMask(m.imageBase64))
        );
        console.timeEnd("masks");
        console.log("maskImages.length", maskImages.length);
    }

    async function convertMask(imageBase64) {
        const img = await loadImage(imageBase64);
        const canvas = imageToCanvas(img);
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let i;
        for (i = 0; i < imgData.data.length; i += 4) {
            imgData.data[i + 3] = 255 - imgData.data[i + 3];
            // imgData.data[i + 3] = imgData.data[i + 3] < 64 ? 0 : 256
            imgData.data[i + 3] = Math.round(0.85 * imgData.data[i + 3]);
        }
        ctx.putImageData(imgData, 0, 0);
        return canvas;
    }
    $: if (dataUrl) {
        updateImages()
    }
</script>

<div class="artwork">
    <img src={imgUrl} alt="prosepainter artwork" />
    {#if maskData != null && maskImages != null}
        <p class="title">{artistName} - {dateString}</p>
        <div class="prompts">
            {#each maskData as m, index}
                <p
                    on:mouseover={() => (hoveredIndex = index)}
                    on:mouseout={() => (hoveredIndex = null)}
                    on:focus={() => (hoveredIndex = index)}
                    on:blur={() => (hoveredIndex = null)}
                    on:click={() => (hoveredIndex = index)}
                    class="prompt"
                >
                    {m.prompt}{index == maskData.length - 1 ? "" : ","}
                </p>
            {/each}
        </div>
        {#if hoveredIndex !== null}
            <img
                class="mask"
                src={maskImages[hoveredIndex].toDataURL()}
                alt="mask"
            />
        {/if}
    {/if}
</div>

<style>
    .artwork {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 512px;
        margin-bottom: 16px;
    }
    p {
        text-align: center;
    }
    p.title {
        margin-bottom: 4px;
        text-transform: capitalize;
    }
    .prompts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    p.prompt {
        font-style: italic;
        cursor: pointer;
        margin: 4px;
    }
    p.prompt:hover {
        text-decoration: underline;
    }
    img.mask {
        position: absolute;
        width: 100%;
    }
</style>
