<script lang="ts">
    import PillRadioButton from "@/components/PillRadioButton.svelte";
    export let options:{value:any, label:string}[]
    export let value:any
    export let style:string =''
</script>

<div class="select-mode" {style}>
    {#each options as opt}
        <PillRadioButton
            label={opt.label}
            style="flex-grow: 1;"
            value={opt.value}
            bind:group={value}
            name="mode"
        />
    {/each}
</div>

<style>
    .select-mode {
        background-color: var(--gray-50);
        border-radius: 24px;
        height: 32px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
</style>