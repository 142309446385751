<script lang="ts">
    import Modal from "./Modal.svelte";
    import { MAX_IMAGE_SIZE, DEFAULT_IMAGES } from "@/constants";
    import { setImageByFile, setImageByURL, setEmpty } from "@/stores/canvases";
    import Button from "./Button.svelte";
    import { isMobile } from "@/stores/ui";

    export let show = false;
    export function toggle() {
        show = !show;
    }
    let width: number = 512;
    let height: number = 512;
    $: width = Math.min(width, MAX_IMAGE_SIZE);
    $: height = Math.min(height, MAX_IMAGE_SIZE);
    async function onFiles(event) {
        const { files } = event.target;
        if (files.length && files[0].type.startsWith("image")) {
            setImageByFile(files[0]);
        }
        show = false;
    }
</script>

<Modal bind:show>
    <div slot="header">
        <h2>New Painting</h2>
    </div>
    <div slot="body" class="create-content">
        
        <p>Start with your own image...</p>
        <label class="custom-file-upload">
            <input type="file" accept=".jpg, .jpeg, .png" on:change={onFiles} />
            Upload Image
        </label>
        <p>...a sample image...</p>

        <div class="template-images">
            {#each DEFAULT_IMAGES as imageUrl}
                <img
                    src={imageUrl}
                    draggable="false"
                    on:click={() => {
                        setImageByURL(imageUrl);
                        show = false;
                    }}
                    alt=""
                />
            {/each}
        </div>
        <p>...or a blank canvas...</p>
        {#if $isMobile}
            <div class="template-images">
                <div
                    class="square empty-image"
                    on:click={() => {
                        setEmpty(512, 512);
                        show = false;
                    }}
                />
                <div
                    class="landscape empty-image"
                    on:click={() => {
                        setEmpty(768, 512);
                        show = false;
                    }}
                />
                <div
                    class="portrait empty-image"
                    on:click={() => {
                        setEmpty(512, 768);
                        show = false;
                    }}
                />
            </div>
        {:else}
            <div id="number-container">
                <input type="number" min="128" max="1024" bind:value={width} />
                <p>x</p>
                <input type="number" min="128" max="1024" bind:value={height} />
            </div>
            <Button
                style="width:150px;max-width:100%;"
                on:click={() => {
                    setEmpty(width, height);
                    show = false;
                }}
            >
                Create</Button
            >
        {/if}
    </div>
</Modal>

<style>
    h2 {
        font-weight: 300;
    }
    .create-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    p,
    label {
        text-align: center;
    }
    #number-container {
        display: flex;
        justify-content: center;
    }
    input[type="number"] {
        width: 50px;
        display: inline-block;
        margin: 4px;
    }
    .template-images {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .template-images img {
        width: 25%;
        cursor: pointer;
    }
    @media screen and (max-width: 720px) {
        .template-images img {
            width: 33%;
        }
    }

    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        cursor: pointer;
        width: 150px;
        max-width: 100%;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 24px;
        border: none;
        padding-left: 8px;
        padding-right: 8px;
        justify-content: center;
        margin-right: 0;
        font-size: 16px;
        color: var(--gray-10);
        background-color: var(--gray-50);
    }
    .empty-image {
        background-color: white;
        border: 1px solid black;
        width: 64px;
        height: 64px;
        margin: 8px;
        cursor: pointer;
    }
    .empty-image.landscape {
        height: 50px;
        width: 75px;
    }
    .empty-image.portrait {
        width: 50px;
        height: 75px;
    }
    /* .square */
</style>
