<script lang="ts">
    import { onMount } from "svelte";
    import Button from "./Button.svelte";
    import Modal from "./Modal.svelte";
    import { createEventDispatcher } from 'svelte'
    export let show = false;

    const dispatch = createEventDispatcher();

    // export let toggle
    export function toggle() {
        show = !show;
    }
    onMount(() => {
        const hasOpened = localStorage.getItem("hasOpened");
        if (hasOpened == null) {
            show = true;
            localStorage.setItem("hasOpened", "1");
        }
    });
</script>

<Modal bind:show>
    <div slot="header">
        <h1>ProsePainter<span class='beta'> BETA</span></h1>
    </div>
    <div slot="body">
        <p> An interactive tool to "paint with words." It incorporates guidable text-to-image generation into a traditional digital painting interface.</p>
        <!-- <p>
            ProsePainter is a new way to digitally paint! You create 'magic-ink'
            from any word or sentence and apply it to an image.
        </p>
        <hr />
        <p>
            The magic-ink transforms images where it is applied, use it to
            modify an image or to create one from sketches.
        </p>
        <p style="margin-bottom:0px;">
            Example: applying the ink <i>"colorful flowers"</i>.
        </p> -->
        
        <div class="image-container">
            <div>
                <p>Start Image</p>
                <img src="/images/modal/before.jpg" alt=''/>
            </div>
            <div>
                <p>Ink Mask</p>
                <img src="/images/modal/mask.jpg" alt=''/>
            </div>
            <div>
                <p>Result</p>
                <img src="/images/modal/after.jpg" alt=''/>
            </div>
        </div>
        <!-- <p>1. First, select a staring image or upload your own.</p>
            <p>2. Try creating </p> -->
        <!-- <video url=''></video> -->
        <!-- <p /> -->
        <br />
        <!-- <hr /> -->
        <div style='display:flex;align-items:center;flex-direction:column;'>
        <Button style='width:60%' on:click={() => {
            dispatch('tryit')
            show = false
        }}>
            Try it out
        </Button>
        <br>
        <Button style='width:60%' on:click={() => window.location.href = '/gallery'}>
            Browse Gallery
            <!-- <a href='/gallery'> </a> -->
            
        </Button>
    </div>
        <p>
            ProsePainter is made by
            <a href="https://www.morphogen.io/">Morphogen</a>, the creators of
            <a href="https://artbreeder.com/">Artbreeder</a>
        </p>
        <!-- <p>
            ProsePainter is made by
            <a href="https://www.morphogen.io/">Morphogen</a>, the creators of
            <a href="https://artbreeder.com/">Artbreeder</a>. It is powered by
            machine-learning and is
            <a href="https://github.com/Morphogens/prosepainter">open sourced</a
            >.
        </p> -->
    </div>
</Modal>

<style>
    h1 {
        font-weight: 400;
    }
    h1,
    p {
        text-align: center;
    }
    a {
        color: black;
    }
    /* button > a {
        color: white;
        text-decoration: none;
    } */
    span.beta {
        font-size: 12px;
        color: var(--red-40);
        transform: translateY(-75%);
        display: inline-block;
    }
    .image-container {
        display: flex;
        justify-content: center;
    }
    .image-container > * {
        display: flex;
        flex-direction: column;
        width: 33%;
    }
    .image-container img {
        width: 100%;
    }
    .image-container p {
        font-weight: bold;
    }
</style>
