import App from './App.svelte'

// Redirect if being accessed over http and no port (so not debugging)
if (window.location.port == '' && window.location.protocol === 'http:') {
  window.location.protocol = 'https:'
}

// Initialize app
const app = new App({
  target: document.getElementById('app')
})

export default app
