<script lang="ts">
import { onMount } from 'svelte'
// import autoresize from 'node_modules/autosize/dist/autosize'
export let style = ''
export let placeholder = 'search'
export let focusOnMount = false
export let value = ''
let input: HTMLTextAreaElement
$: value = value.replace(/(\r\n|\n|\r)/gm, "").replace(/[^a-zA-Z .-]/g, "")

onMount(() => {
    // @ts-expect-error
    autosize(input)
})
// onMount(maybeFocusOnMount)

// function maybeFocusOnMount() {
//     if (focusOnMount) {
//         input.focus()
//     }
// }
</script>
<!-- 
<input
    type="search"
    {placeholder}
    {style}
    bind:this={input}
    bind:value
/> -->

<textarea rows="2" bind:this={input} {style} {placeholder} bind:value />

<style>
textarea {
    border-radius: 16px;
    border: none;
    height: 32px;
    line-height: 28px;
    text-indent: 8px;
    font-size: 16px;
    resize: none;
}

textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gray-50);
    opacity: 1; /* Firefox */
    text-transform: lowercase;
    font-size: 16px;
}

textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--gray-50);
}

/* clears the 'X' from Internet Explorer */
textarea::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
textarea::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
textarea::-webkit-search-decoration,
textarea::-webkit-search-cancel-button,
textarea::-webkit-search-results-button,
textarea::-webkit-search-results-decoration {
    display: none;
}
</style>
