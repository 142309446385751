<script lang="ts">
    import { Mode } from "@/types";
    import { mode } from "@/stores/ui";
    import { mainCanvas, maskCanvas } from "@/stores/canvases";
    import Slider from "@/components/Slider.svelte";
    import Button from "../Button.svelte";
    export let upwards = false
    $: activeCanvas = $mode == Mode.DirectDraw ? $mainCanvas : $maskCanvas;
    let showBrushControls = false;    
</script>
<!-- <svelte:body on:click={() => (showBrushControls = false)} /> -->
<div class="brush-preview-container">
    {#if showBrushControls}
        <div class="controls" class:upwards>
            <img
                class="brush-preview"
                src={activeCanvas.brushImageSrc}
                alt="brush-preview"
            />
            <Slider
                name="Radius"
                bind:val={activeCanvas.radius}
                min={1}
                max={96}
                step={1}
            />
            <Slider
                name="Opacity"
                bind:val={activeCanvas.opacity}
                min={0.0}
                max={0.9}
                step={0.05}
            />
            <Slider
                name="Hardness"
                bind:val={activeCanvas.hardness}
                max={1}
                step={0.05}
            />
        </div>
    {/if}
    <Button style='' on:click={() => (showBrushControls = !showBrushControls)}>
        
        <p style='white-space: nowrap'> Brush Options </p>

    </Button>
</div>

<style>
    .brush-preview-container {
        display: flex;
        /* margin-right: 4px; */
    }
    .controls {
        /* bottom: 96px; */
        position:absolute;
        /* width: 156px; */
        background-color: var(--gray-90);
        padding: 8px;
        border-radius: 8px;
        transform: translateY(38px);
    }
    .controls.upwards {
        transform: translateY(-100%);
    }
    .brush-preview-container {
        border-radius: 50%;
    }
    .brush-preview {
        width: 48px;
        height: 48px;
        padding: 1px;
        background-color: white;
        /* padding: 4px; */
    }
    .brush-preview-container img {
        border-radius: 50%;
        cursor: pointer;
        /* margin-right: 2px; */
    }
</style>
