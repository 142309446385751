import { writable, derived, get } from 'svelte/store'
import { canvasSize } from '@/stores/canvases'
import { innerWidth, innerHeight, zoom } from '@/stores/ui'
export const viewer = writable(null as null | any)

export function fitImage() {
    const $zoom = Math.min(1, Math.min(
        get(innerWidth) / get(canvasSize)[0],
        get(innerHeight) / get(canvasSize)[1]
    ) * .85)        
    zoom.set($zoom)
    get(viewer).scrollCenter()
}