import type { Writable } from 'svelte/store'
import { writable } from 'svelte/store'
import { clamp } from '@/utils'

export function localStorageWritable<T>(name:string, defaultValue:T):Writable<T>{
    // Svelte store that persists to localStorage.
    // Data must be JSON-able.
    let value = null
    try {
        value = JSON.parse(localStorage.getItem(name))
    } catch (error) {}
    const store = writable(value ?? defaultValue)
    store.subscribe($value => {
        localStorage.setItem(name, JSON.stringify($value))
    })
    return store
}

export function clampedNumberStore(value:number, min:number, max:number) {
    const store = writable(value)
    return {
        subscribe: store.subscribe,
        set: (v:number) => store.set(clamp(v, min, max))
    }
}