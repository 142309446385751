<script lang="ts">
    import Artwork from "@/components/gallery/Artwork.svelte";
    import { postJSON } from "@/utils/network";
    import { onMount } from "svelte";
    import config from "@/config";
    import type { ArtworkData } from "@/types";

    let arworkData: ArtworkData[] = [];
    onMount(async () => {
        arworkData = await postJSON(`${config.apiURL}/gallery`, {});
    });
</script>

<h1>Gallery</h1>
<div class="gallery">
    {#each arworkData as data}
        <Artwork
            key={data.key}
            artistName={data.artist_name}
            creationDate={new Date(data.created_at)}
        />
    {/each}
</div>

<div id='paint'>
    <a href='/'>Start Painting </a>
    
</div>

<style>
    h1 {
        font-weight: 300;
        text-align: center;
    }
    .gallery {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #paint {
        position: fixed;
        right: 8px;
        top: 8px;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 24px;
        background-color: var(--green-40);
        
    }
    #paint a {
        color:  black;
        text-decoration: none;
    }
</style>
