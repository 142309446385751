<script lang="ts">
    import router from "page";
    import Paint from "@/routes/Paint.svelte";
    import Gallery from "@/routes/Gallery.svelte";
    import Review from "@/routes/Review.svelte";
    let page;
    let params;
    router("/", () => (page = Paint));
    router("/paint", () => (page = Paint));
    router("/gallery", () => (page = Gallery));
    router("/review", () => (page = Review));
    router.start();
</script>

<svelte:component this={page} {params} />


<style>
     :global(*) {
        /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
        font-family: "Open Sans", sans-serif;
        box-sizing: border-box;
    }
    :global(body) {
        margin: 0px;
    }
    :global(button) {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        margin: 0px;
        /* background-color: white; */
        background-color: #f8f9fa;
        opacity: 1;

        cursor: pointer;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    }
    :global(button:hover) {
        background-color: #f8f9fa79;
    }
</style>