<script lang="ts">
    export let show = false;
    import { isMobile } from "@/stores/ui";
    import closeURL from "@/assets/x.svg";

    function closeModalIfEscape(event: KeyboardEvent) {
        if (event.key === "Escape") {
            show = false;
        }
    }
    function open() {
        console.log('opening modal');
        show = true
    }
    
</script>

<slot name="trigger" {open} />
<svelte:body on:keydown={closeModalIfEscape} />

{#if show}
    <div class="modal-background" on:click|self={() => (show = false)}>
        <div class="modal-content" class:isMobile={$isMobile}>
            <div class="header-container">
                <img class="close-button" style='visibility:hidden' src={closeURL} alt=''/>
                <div class="header-container-inner">
                    <slot name="header" />
                </div>
                <img
                    class="close-button"
                    src={closeURL}
                    alt="close"
                    on:click={() => (show = false)}
                />
            </div>
            <slot name="body"  style="width:100%" />
        </div>
    </div>
{/if}

<style>
    .modal-background {
        position: fixed;
        z-index: 99;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(169, 169, 169, 0.336);
        backdrop-filter: blur(2px);
        cursor: pointer;
    }
    .modal-content {
        width: 512px;
        min-height: 256px;
        max-width: 80%;
        background-color: var(--gray-0);
        cursor: default;
        border: 1px solid #ccc;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        z-index: 9;
    }
    .modal-content.isMobile {
        width: 100vh;
        max-width: 100vh;
        height: 100vh;
    }
    .header-container {
        display: flex;
        /* justify-content: center; */
    }
    .header-container-inner {
        display: flex;
        flex-grow: 1;
        text-align: center;
        width: 100%;
        justify-content: center;
    }
    .close-button {
        cursor: pointer;
        margin: 8px
    }
</style>
