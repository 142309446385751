<script lang="ts">
    import { onMount } from "svelte";
    import { get } from "svelte/store";
    import DrawCanvas from "@/drawing/DrawCanvas.svelte";
    import { Mode } from "@/types";
    import { isMobile, mode, selectedOptImage, zoom } from "@/stores/ui";
    import {
        mainCanvas,
        maskCanvas,
        canvasSize,
    } from "@/stores/canvases";
    import { drawCircle } from "@/drawing/drawUtils";
    import { drawMaskGridAlpha, drawGrid } from "@/maskDrawMethods";
    import { DEFAULT_IMAGE } from "@/constants";

    let mouseover = false;
    let cursorCanvas: HTMLCanvasElement;
    let cursorCtx: CanvasRenderingContext2D;

    let outlineCanvas: HTMLCanvasElement;
    let outlineCtx: CanvasRenderingContext2D;
    $: magicMaskFilter = drawGrid($canvasSize, 3);
    $: outineLineWidth = $isMobile ? 2 : 1
    $: if (!mouseover && cursorCtx) {
        cursorCtx.clearRect(0, 0, $canvasSize[0], $canvasSize[1]);
    }

    function activeDrawCanvas(): DrawCanvas {
        if ($mode == Mode.DirectDraw) {
            return get(mainCanvas);
        } else if ($mode == Mode.MaskDraw) {
            return get(maskCanvas);
        }
    }

    function onCanvasMouseMove(event) {
        const [x, y] = [event.offsetX, event.offsetY];
        cursorCtx.clearRect(0, 0, $canvasSize[0], $canvasSize[1]);
        const canvas = activeDrawCanvas();
        cursorCtx.lineWidth = 1;
        cursorCtx.strokeStyle = "white";
        if (canvas) {
            // cursorCtx.fillStyle = canvas.strokeColor;
            // drawCircle(cursorCtx, x, y, canvas.radius, true, true);
            cursorCtx.strokeStyle = 'white';
            cursorCtx.lineWidth = .5;
            drawCircle(cursorCtx, x, y, canvas.radius+1, false, true);
            cursorCtx.strokeStyle = 'black';
            cursorCtx.lineWidth = .5;
            drawCircle(cursorCtx, x, y, canvas.radius, false, true);
        } else {
            cursorCtx.fillStyle = "black";
            drawCircle(cursorCtx, x, y, 2, true, true);
        }
    }

    
    function onMaskCanvasStroke(data) {
        const { ctx, canvas } = data.detail;
        drawMaskGridAlpha(
            outlineCtx,
            $canvasSize as [number, number],
            canvas,
            magicMaskFilter[0],
            outineLineWidth
        );
    }

    function onMaskCanvasChange(data) {
        const { canvas, ctx } = data.detail;
        drawMaskGridAlpha(
            outlineCtx,
            $canvasSize as [number, number],
            canvas,
            magicMaskFilter[0],
            outineLineWidth
        );
    }
    onMount(async () => {
        $mainCanvas.strokeColor = "#e66465";
        cursorCtx = cursorCanvas.getContext("2d");
        outlineCtx = outlineCanvas.getContext("2d");

        
    });
</script>
<div
    id="content"
    style="width:{$canvasSize[0]}px;height:{$canvasSize[1]}px"
    on:mousemove={onCanvasMouseMove}
    on:mouseover={() => (mouseover = true)}
    on:mouseout={() => (mouseover = false)}
    on:focus={() => (mouseover = true)}
    on:blur={() => (mouseover = false)}
>
    <div style="opacity:1;">
        <DrawCanvas
            {canvasSize}
            radius={20}
            hardness={0.5}
            style="image-rendering:{$zoom > 3 ? 'pixelated' : ''};"
            id="mainCanvas"
            defaultImageUrl={DEFAULT_IMAGE}
            showCursor={false}
            bind:this={$mainCanvas}
        />
    </div>
    <div class:hidden={$mode == Mode.DirectDraw}>
        <div style="opacity:0;">
            <DrawCanvas
                radius={50}
                hardness={0.2}
                {canvasSize}
                id="maskCanvas"
                maskFilter={magicMaskFilter[0]}
                showCursor={false}
                on:change={onMaskCanvasChange}
                on:stroke={onMaskCanvasStroke}
                bind:this={$maskCanvas}
            />
        </div>
    </div>
    <div class:hidden={$mode != Mode.MaskDraw}>
        <canvas
            id="outlineCanvas"
            class="hiddenOverlay"
            width={$canvasSize[0]}
            height={$canvasSize[1]}
            bind:this={outlineCanvas}
        />
    </div>
    {#if $selectedOptImage}
        <img
            id="optPreview"
            class="hiddenOverlay"
            src={$selectedOptImage.src}
            alt=""
        />
    {/if}
    <canvas
        id="cursorCanvas"
        class="hiddenOverlay"
        width={$canvasSize[0]}
        height={$canvasSize[1]}
        bind:this={cursorCanvas}
    />
</div>


<style>
    #content {
        display: flex;
    }
    .hiddenOverlay {
        pointer-events: none;
    }
</style>