<script lang="ts">
    import PillRadioButton from "@/components/PillRadioButton.svelte";
    import SearchInput from "@/components/SearchInput.svelte";
    import { Mode } from "@/types";
    import { isMobile, mode, prompt } from "@/stores/ui";
    import { mainCanvas, maskCanvas } from "@/stores/canvases";
    // import Slider from "@/components/Slider.svelte";
    import * as optEvents from "@/optimizeEvents";
    // import OptimizeOptions from "./bottomControls/OptimizeOptions.svelte";
    import Button from "@/components/Button.svelte";
    import BrushPreview from "@/components/bottomControls/BrushPreview.svelte";
    import Toggle from "@/components/Toggle.svelte";
</script>

<div id="draw-mask-content" class:isMobile={$isMobile}>
    <div class="row">
        <SearchInput
            bind:value={$prompt}
            placeholder="What would you like to paint?"
            style="display: flex;flex-grow: 1;width:100%';max-width:400px"
            focusOnMount={false}
        />
    </div>
    <div class="row buttons">
        <BrushPreview upwards={$isMobile} />
        <Button style="" on:click={() => $maskCanvas?.clear()}>Clear</Button>
        <Button style="" on:click={() => $maskCanvas?.fill()}>All</Button>
        {#if $isMobile}
            <Button
                backgroundColor={$maskCanvas.erasing ? "green-40" : "gray-50"}
                on:click={() => ($maskCanvas.erasing = !$maskCanvas.erasing)}
            >
                <img style="width:18px" src="/images/eraser.png" alt="erase-mask" />
            </Button>
        {:else}
            <Toggle
                style=""
                options={[
                    { label: "Paint", value: false },
                    { label: "Erase", value: true },
                ]}
                bind:value={$maskCanvas.erasing}
            />
        {/if}

        {#if !$isMobile}
            <Button
                backgroundColor={"green-40"}
                color={"gray-90"}
                on:click={() => optEvents.start()}>Start</Button
            >
        {/if}
    </div>
</div>

<style>
    #draw-mask-content {
        display: flex;
        width: 100%;
    }
    #draw-mask-content.isMobile {
        flex-direction: column-reverse;
        width: 100%;
    }
    #draw-mask-content.isMobile .row {
        justify-content: center;
    }
    .row {
        display: contents;
    }
    .isMobile .row {
        display: flex;
        /* margin: 4px; */
    }
    .isMobile .row.buttons {
        overflow-x: scroll;
    }
    :global(.row > *) {
        margin: 4px;
    }
</style>
