<script lang="ts">
    import { Mode } from "@/types";
    import { isMobile, mode } from "@/stores/ui";
    import { mainCanvas, maskCanvas } from "@/stores/canvases";
    import OptimizeOptions from "./bottomControls/OptimizeOptions.svelte";
    import Button from "./Button.svelte";
    import BrushPreview from "./bottomControls/BrushPreview.svelte";
    // import Toggle from "@/components/Toggle.svelte";
    import MaskDrawControls from "@/components/bottomControls/MaskDrawControls.svelte";
    import ProseButton from "./ProseButton.svelte";
</script>

{#if $mainCanvas && $maskCanvas}
    <div class="outer-container" class:isMobile={$isMobile}>
        <div class="left">
            <ProseButton type="create" />
        </div>
        <div class="center">
            <div
                class="content-bar"
                class:maskDraw={$mode == Mode.MaskDraw}
                style="flex-grow:{$mode == Mode.MaskDraw ? '1' : '0'}"
            >
                {#if $mode == Mode.MaskDraw}
                    <MaskDrawControls />
                {:else if $mode == Mode.Optimizing || $mode == Mode.PausedOptimizing}
                    <OptimizeOptions />
                {:else if $mode == Mode.DirectDraw}
                    <div style="margin:4px">
                        <input
                            type="color"
                            bind:value={$mainCanvas.strokeColor}
                        />
                    </div>
                    <div style="margin:4px">
                        <BrushPreview />
                    </div>
                {:else if $mode == Mode.MobileHomeScreen}
                    <div class="row">
                        <Button on:click={() => ($mode = Mode.DirectDraw)}
                            >Sketch</Button
                        >
                        <Button on:click={() => ($mode = Mode.MaskDraw)}
                            >ProsePaint</Button
                        >
                    </div>
                {/if}
            </div>
            {#if $mode == Mode.MaskDraw}
                <Button
                    style="margin-left:4px"
                    on:click={() => ($mode = Mode.DirectDraw)}>Sketch</Button
                >
            {:else if $mode == Mode.DirectDraw}
                <Button
                    style="margin-left:4px"
                    on:click={() => ($mode = Mode.MaskDraw)}>ProsePaint</Button
                >
            {/if}
        </div>
        <div class="right">
            <ProseButton type="info" />
        </div>
    </div>
{/if}

<style>
    .outer-container {
        position: fixed;
        width: 100vw;
        z-index: 1;
        display: flex;
        /* align-items: center; */
        /* justify-content: center; */
        pointer-events: none;
        top: 8px;
    }
    .outer-container .left {
        display: flex;
        flex-grow: 0;
        flex-shrink: 1;
        justify-content: flex-start;
    }
    .outer-container .center {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
    }
    .outer-container .right {
        display: flex;
        flex-grow: 0;
        flex-shrink: 1;
        justify-content: end;
    }
    .outer-container.isMobile {
        bottom: 8px;
        top: auto;
    }
    :global(.outer-container > *) {
        pointer-events: all;
        margin-left: 4px;
        margin-right: 4px;
    }
    .content-bar {
        background-color: var(--gray-90);
        border-radius: 24px;
        padding: 4px;
        display: flex;
        position: relative;
        max-width: 800px;
    }
    .outer-container.isMobile .content-bar {
        /* width: 100%;
        max-width: 100%; */
        background-color: inherit;
        justify-content: center;
    }
    /* :global(.content-bar > *) {
        margin: 4px;
    } */
    .row {
        flex-direction: row;
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    /* .select-mode {
        background-color: var(--gray-50);
        border-radius: 24px;
        height: 32px;
        display: flex;
        align-items: center;
        flex-direction: row;
    } */

    input[type="color"] {
        cursor: pointer;
        display: flex;
        /* visibility: hidden; */
        /* position: absolute; */
        height: 32px;
        /* width: 80%; */
        width: 150px;
        flex-shrink: 0;
    }

    /* .drop-up {
        position: absolute;
        bottom: 48px;
        border-radius: 24px;
        padding: 8px;
        left: 50%;
        transform: translateX(-50%);
    } */
</style>
