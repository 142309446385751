import { writable, get, derived } from 'svelte/store'
import { tick } from "svelte";
import { localStorageWritable } from '@/stores/storeUtils'
import { mode } from '@/stores/ui'
import type DrawCanvas from "@/drawing/DrawCanvas.svelte";
import * as drawHistory from '@/stores/drawHistory'
import { loadImage, imageToCanvas, thumbnailCanvas } from "@/utils";
import { MAX_IMAGE_SIZE } from "@/constants";
import { Mode } from '@/types';

export const canvasSize = localStorageWritable('canvasSize', [512, 512])
export const mainCanvas = writable(null as null | DrawCanvas)
export const maskCanvas = writable(null as null | DrawCanvas)
export const canvasEvents: EventTarget = new EventTarget()

export const activeCanvas = derived(
    [mode, maskCanvas, mainCanvas],
    ([$mode, $maskCanvas, $mainCanvas]) => {
        if (!$mainCanvas || !$maskCanvas) {
            return null
        }
        return ($mode == Mode.DirectDraw) ? $mainCanvas : $maskCanvas
})

export async function setEmpty(width, height) {
    console.log('set empty', width, height);
    
    canvasSize.set([width, height]);
    await tick(); // DrawCanvases get recreated.
    const whiteCanvas = document.createElement('canvas')
    whiteCanvas.width = width
    whiteCanvas.height = height
    const ctx = whiteCanvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, width, height)

    get(mainCanvas).setImage(whiteCanvas)
    drawHistory.reset()
    canvasEvents.dispatchEvent(new CustomEvent('setImage', {}))
}

export async function setImageByURL(imageUrl: string) {
    try {
        const image = await loadImage(imageUrl);
        const canvas = thumbnailCanvas(imageToCanvas(image), MAX_IMAGE_SIZE);
        canvasSize.set([canvas.width, canvas.height]);
        await tick(); // DrawCanvases get recreated.
    
        get(mainCanvas).setImage(canvas);
        drawHistory.reset()
        canvasEvents.dispatchEvent(new CustomEvent('setImage', {}))
    } catch (error) {
        console.log(error);
        return
    }
}

export async function setImageByFile(file: File) {
    const url = URL.createObjectURL(file)
    await setImageByURL(url)
}