
export function drawCircle(
    ctx,
    x,
    y,
    r,
    fill = true,
    stroke = false,
    clip = false
) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI, false);
    if (clip) {
        ctx.clip();
    }
    if (stroke) {
        ctx.stroke();
    }
    if (fill) {
        ctx.fill();
    }
    ctx.closePath();
}

export function drawLine(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    radius: number
) {
    ctx.lineWidth = radius
    ctx.beginPath();
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
}

export function drawLines(
    ctx: CanvasRenderingContext2D,
    points: number[][]
) {
    if (points.length < 2) {
        return
    }
    ctx.beginPath()
    ctx.moveTo(points[0][0], points[0][1])
    for (let idx = 1; idx < points.length; idx++) {
        ctx.lineTo(points[idx][0], points[idx][1])
    }
    ctx.stroke()
}

export function drawSmoothLines(
    ctx: CanvasRenderingContext2D,
    points: number[][]
) {
    if (points.length < 3) {
        return
    }
    ctx.beginPath()
    ctx.moveTo(points[0][0], points[0][1])
    let i
    for (i = 1; i < points.length - 2; i++) {
        let xc = (points[i][0] + points[i + 1][0]) / 2;
        let yc = (points[i][1] + points[i + 1][1]) / 2;
        ctx.quadraticCurveTo(points[i][0], points[i][1], xc, yc);
    }
    // curve through the last two points
    ctx.quadraticCurveTo(points[i][0], points[i][1], points[i + 1][0], points[i + 1][1]);
    ctx.stroke()
}


export function createBrushImage(
    hardness: number,
    radius: number,
    opacity: number = 0.0,
    color1: string='rgba(0, 0, 0, 1.0)',
    color2: string='rgba(255, 255, 255, 1.0)',
): HTMLCanvasElement {
    const size = (radius * 2 + 1)
    const canvas = document.createElement('canvas') as HTMLCanvasElement
    canvas.width = size
    canvas.height = size
    const context = canvas.getContext('2d')

    context.globalAlpha = 1 - opacity
    const gradient = context.createRadialGradient(
        radius+1,
        radius+1,
        Math.min(radius-1, Math.round(radius * hardness)),
        radius+1,
        radius+1,
        radius
    );
    gradient.addColorStop(0, color1)
    gradient.addColorStop(1, color2)
    context.fillStyle = gradient
    context.fillRect(0, 0, size, size)
    return canvas
}


export function drawBrushLine(
    context:CanvasRenderingContext2D,
    brushImage: HTMLImageElement|HTMLCanvasElement,
    x1:number,
    y1: number,
    x2: number,
    y2: number
) {
    const bw = brushImage.width
    const bh = brushImage.height
    const diffX = Math.abs(x2 - x1)
    const diffY = Math.abs(y2 - y1)
    const dist = Math.sqrt(diffX * diffX + diffY * diffY)
    // const step = .25 * bw / (dist ? (Math.min(24, dist)) : 1)
    const step = .05 * bw
    let i = 0
    let t = 0, b, x, y;
    // console.log(dist, step, dist / step);
    
    context.globalCompositeOperation = 'darken'
    while (i <= dist) {
        t = Math.max(0, Math.min(1, i / dist));
        x = x1 + (x2 - x1) * t;
        y = y1 + (y2 - y1) * t;
        b = (Math.random() * 3) | 0;
        context.drawImage(brushImage, x - bw * 0.5, y - bh * 0.5);
        i += step
    }
    context.globalCompositeOperation = 'source-over'
}