<script lang="typescript">
    export let onDrop;
    import { stopEvent } from "../utils";
</script>

<div
    on:dragstart={stopEvent}
    on:dragover={stopEvent}
    on:dragleave={stopEvent}
    on:drop|preventDefault={onDrop}
>
    <slot />
</div>
