export async function loadImage(src: string): Promise<HTMLImageElement> {
    return await new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin="anonymous"
        image.src = src
        image.onload = () => resolve(image)
        image.onerror = () => reject(new Error('could not load image'))
    })
}

export async function loadJson<T>(path: string): Promise<T> {
    return await fetch(path).then(async(response) => await response.json())
}

export function imageToCanvas(img: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    canvas.height = img.naturalHeight
    canvas.width = img.naturalWidth
    ctx.drawImage(img, 0, 0)
    return canvas
}

export function canvasToImage(canvas: HTMLCanvasElement): HTMLImageElement {
    const image = new Image()
    image.src = canvas.toDataURL()
    return image
}

export function imgTob64(img: HTMLImageElement): string {
    const canvas = imageToCanvas(img)
    return canvas.toDataURL()
}

export function downloadCanvas(
    canvas: HTMLCanvasElement,
    filename: string = 'download.png',
    format: string = 'image/png',
    quality:number = 1
) {
    const url = canvas.toDataURL(format, quality)
    const aDownloadLink = document.createElement('a')
    aDownloadLink.download = filename
    aDownloadLink.href = url
    aDownloadLink.click()
}


export function mergeCanvas(
    canvas1: HTMLCanvasElement,
    canvas2: HTMLCanvasElement
): HTMLCanvasElement {
    const newCanvas = document.createElement('canvas')
    const newCtx = newCanvas.getContext('2d')
    newCanvas.width = canvas1.width
    newCanvas.height = canvas1.height
    newCtx.drawImage(canvas1, 0, 0)
    newCtx.drawImage(canvas2, 0, 0)
    return newCanvas
}

export function thumbnailCanvas(
    canvas: HTMLCanvasElement,
    maxSize: number
): HTMLCanvasElement {
    const scale = Math.min(1, maxSize / Math.max(canvas.height, canvas.width))
    const thumbnail = document.createElement('canvas')
    const thumbnailCtx = thumbnail.getContext('2d')
    thumbnail.width = Math.round(scale * canvas.width)
    thumbnail.height = Math.round(scale * canvas.height)
    thumbnailCtx.drawImage(
        canvas,
        0, 0,
        canvas.width, canvas.height,
        0, 0,
        thumbnail.width,
        thumbnail.height
    )
    return thumbnail
}

export function clamp(a: number, b: number, c: number): number {
    return Math.max(b, Math.min(c, a))
}

export function stopEvent(event) {
    event.stopPropagation()
    event.preventDefault()
}

export function localStorageSpace(){
    let data = '';
    console.log('Current local storage: ');
    for(let key in window.localStorage){
        if(window.localStorage.hasOwnProperty(key)){
            data += window.localStorage[key];
            console.log( key + " = " + ((window.localStorage[key].length * 16)/(8 * 1024)).toFixed(2) + ' KB' );
        }
    }
    console.log(data ? '\n' + 'Total space used: ' + ((data.length * 16)/(8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)');
    console.log(data ? 'Approx. space remaining: ' + (5120 - ((data.length * 16)/(8 * 1024)).toFixed(2)) + ' KB' : '5 MB');
};

export function downloadJSON(exportObj:any, exportName:string) {
    if (!exportName.endsWith('.json')) {
        exportName += '.json'
    }
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute("href", dataStr)
    downloadAnchorNode.setAttribute("download", exportName)
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}
