<script lang="ts">
    import { Mode } from "@/types";
    import { mode, isMobile } from "@/stores/ui";
    import ProseButton from "@/components/ProseButton.svelte";
</script>

<div id="mobile-header-container">
    {#if $mode == Mode.MobileHomeScreen}
        <div class='left'>
            <ProseButton type="create" />
        </div>
        <div class='center button-group'>
        </div>
        <div class='right'>
            <ProseButton type="info" />
            <ProseButton type="download" />
        </div>
    {:else if $mode == Mode.MaskDraw}
        <div class='left'>
            <ProseButton type="home" />
        </div>
        <div class='center button-group'>
            <ProseButton type="undo" />
        </div>
        <div class='right'>
            <ProseButton type="start" />
        </div>
    {:else if $mode == Mode.DirectDraw}
        <div class='left'>
            <ProseButton type="home" />
        </div>
        <div class='center button-group'>
            <ProseButton type="undo" />
        </div>
        <div class='right'></div>
    {/if}
</div>


<style>
    #mobile-header-container {
        width: 100%;
        position: fixed;
        top: 0px;
        display: flex;
        z-index: 1;
        pointer-events: none;
        /* justify-content: space-between; */
        padding: 4px;
        align-items: center;
    }
    #mobile-header-container  > * {
        display: flex;
        flex-grow: 1;
        width: 33%;
    }
    #mobile-header-container .left {
        
    }
    #mobile-header-container .center {
        justify-content: center;

    }
    #mobile-header-container .right {
        justify-content: flex-end;
    }
    :global(#mobile-header-container > *) {
        pointer-events: all;
    }
    .button-group {
        display: flex;
        flex-direction: row;
        /* height: 100%; */
    }
</style>