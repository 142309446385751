export function mouseOrTouchOffsets(event): [number, number][] {
    if (event.touches?.length) {
        return Array.from(event.touches).map(({ clientX, clientY , target }) => {            
            const r = target.getBoundingClientRect();
            const scale = target.clientWidth / r.width            
            const offsetX = clientX - r.left
            const offsetY = clientY - r.top
            return [ offsetX * scale,  offsetY * scale ]
        })
    } else {
        return [[ event.offsetX, event.offsetY ]]
    }
}