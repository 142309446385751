
import { writable, derived, get } from 'svelte/store'
import type { OptimizationResults } from '@/types'
import { Mode } from '@/types'
import { localStorageWritable, clampedNumberStore } from '@/stores/storeUtils'
import { onMount } from 'svelte'


export const prompt = localStorageWritable('prompt', 'radiant fireworks of diamonds')
export const stylePrompt = localStorageWritable('stylePrompt', '')
export const learningRate = localStorageWritable('learningRate', 250)
export const numRecSteps = localStorageWritable('numRecSteps', 0)
export const zoom = clampedNumberStore(1, .25, 5.0)
export const innerWidth = writable(window.innerWidth)
export const innerHeight = writable(window.innerHeight)
export const isMobile = derived(innerWidth, $w => $w <= 900)
export const mode = writable(
    get(isMobile) ? Mode.MobileHomeScreen : Mode.MaskDraw
)

// UserPool
export const userQueuePosition = writable(-1)
export const optimizationResults = writable(null as null | OptimizationResults)
export const selectedOptIdx = writable(0)
export const selectedOptImage = derived([selectedOptIdx, optimizationResults], ([$idx, $results]) => {
    return $results == null ? null : $results.images[$idx]
})

