<svelte:options accessors />

<script lang="ts">
    import type { Writable } from 'svelte/store'
    import { writable } from 'svelte/store'
    import { onMount, createEventDispatcher } from "svelte";
    import { drawBrushLine, createBrushImage } from "@/drawing/drawUtils";
    import { canvasToImage, clamp, loadImage, thumbnailCanvas } from "@/utils";
    
    export let style = ''
    export let id: string;
    export let radius = 30;
    export let opacity = 0;
    export let hardness = .8;
    export let erasing = false;
    export let showCursor = true;
    export let canvasBase64: string | null = null;
    export let strokeColor = "#000000";
    export let defaultImageUrl: string | null = null;
    export let maskFilter: HTMLCanvasElement | null = null; // Temporary hack.
    export let canvasSize: Writable<number[]> = writable([512, 512])
    export let brushImageSrc = ""
    import { mouseOrTouchOffsets } from '@/utils/events'
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    
    const dispatch = createEventDispatcher()
    const strokeCanvasScale = 1.0

    $: width = $canvasSize[0]
    $: height = $canvasSize[1]
    
    $: strokeWidth = Math.round(width * strokeCanvasScale)
    $: strokeHeight = Math.round(height * strokeCanvasScale)
    
    let canvas: HTMLCanvasElement;
    let ctx: CanvasRenderingContext2D;

    let strokeCanvas: HTMLCanvasElement;
    let strokeCtx: CanvasRenderingContext2D;
    let grayStrokeCanvas: HTMLCanvasElement = document.createElement("canvas");;
    let grayStrokeCtx: CanvasRenderingContext2D = grayStrokeCanvas.getContext("2d");;

    let previewCanvas: HTMLCanvasElement = document.createElement("canvas");
    let previewCtx: CanvasRenderingContext2D = previewCanvas.getContext("2d");

    let undoStack: string[] = [];
    let undoStackMax = 30;
    let redoStack: string[] = [];
    let mouseDown = false;
    let currentStroke: number[][] = [];
    
    const brushImage = writable(new Image())
    $: canvasBase64 = undoStack[undoStack.length - 1] ?? null;
    $: brushCanvas = createBrushImage(hardness, radius, opacity)
    // $: brushCanvasPreview = createBrushImage(hardness, 64, opacity, 'rgba(255, 255, 255, 1.0)', 'rgba(255, 255, 255, 0.0)')
    $: brushCanvasPreview = createBrushImage(hardness, 64, opacity, 'rgba(0, 0, 0, 1.0)', 'rgba(255, 255, 255, 0.0)')
    $: $brushImage = canvasToImage(brushCanvasPreview)
    $: brushImageSrc = $brushImage.src
    $: strokeColorRGB = hexToRgb(strokeColor)
    
    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    function canvasChanged() {
        if (undoStack.length === undoStackMax) {
            undoStack.shift();
        }
        redoStack = [];
        const dataURl = canvas.toDataURL();
        
        // console.log(
        //     thumbnailCanvas(canvas, 256).toDataURL('image/png').length,
        //     thumbnailCanvas(canvas, 256).toDataURL('image/jpeg').length,
        //     thumbnailCanvas(canvas, 256).toDataURL('image/webp').length,
        // );
        // var a = document.createElement("a"); //Create <a>
        // a.href = thumbnailCanvas(canvas, 256).toDataURL('image/webp'); //Image Base64 Goes here
        // a.download = "Image.webp"; //File name Here
        // a.click(); //Downloaded file
        
        undoStack = [...undoStack, dataURl];
        dispatch("change", { ctx, canvas, canvasBase64 });
        window.localStorage.setItem(`${id}-canvasBase64`, dataURl);
    }

    export function clear() {
        ctx.clearRect(0, 0, width, height);
        canvasChanged();
    }

    export function fill(fillStyle: string = strokeColor) {
        ctx.fillStyle = fillStyle
        // The -1 is a hack so the contours will work with mask.
        ctx.fillRect(1, 1, width-1, height-1)
        canvasChanged();
    }

    export function getStores() {
        return { brushImage }
    }

    $: if (canvas && width && strokeWidth) {
        // canvas.width = width;
        canvas.height = height;
        strokeCanvas.width = strokeWidth
        strokeCanvas.height = strokeHeight
        previewCanvas.width = width
        previewCanvas.height = height
        grayStrokeCanvas.width = strokeWidth
        grayStrokeCanvas.height = strokeHeight
        strokeCtx.lineCap = 'round'
        strokeCtx.lineJoin = 'round'
    }

    export function setImage(src: HTMLCanvasElement | HTMLImageElement) {
        console.log("set image")
        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(src, 0, 0);
        canvasChanged();
    }

    export function getCanvas(): HTMLCanvasElement {
        return canvas;
    }

    export function getContext(): CanvasRenderingContext2D {
        return ctx;
    }

    async function setFromUndoRedo() {
        ctx.clearRect(0, 0, width, height);
        if (undoStack.length) {
            const image = await loadImage(undoStack[undoStack.length - 1]);
            ctx.drawImage(image, 0, 0);
        }
        dispatch("change", { ctx, canvas, canvasBase64 });
    }

    export async function undo() {
        const lastbase64 = undoStack.pop();
        await setFromUndoRedo();
        if (lastbase64) {
            redoStack.push(lastbase64);
        }
    }
    export async function redo() {
        const lastbase64 = redoStack.pop();
        if (lastbase64) {
            undoStack.push(lastbase64);
        }
        await setFromUndoRedo();
    }

    export function hasAnyVisiblePixels() {
        const { data } = ctx.getImageData(0, 0, width, height)
        // const u32 =  new Uint32Array(data.data.buffer) // reads 1x uint32 instead of 4x bytes
        for (let index = 0; index < data.length; index+=4) {
            const alpha = data[index +3]
            if (alpha > 0) {
                return true
            }
        }
        return false
    }

    onMount(async () => {
        ctx = canvas.getContext("2d");
        // Draw the start image that was saved
        strokeCtx = strokeCanvas.getContext("2d", { willReadFrequently: true })

        const savedUrl = window.localStorage.getItem(`${id}-canvasBase64`);
        const startUrl = savedUrl || defaultImageUrl;
        if (startUrl != null && startUrl != "null") {
            setImage(await loadImage(startUrl))
        }
    });

    function strokeDone() {
        if (!mouseDown) {
            return;
        }
        mouseDown = false;
        if (currentStroke.length == 0) {
            return
        }
        currentStroke = [];
        console.log('strokeDone');
        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(previewCanvas, 0, 0, width, height)
        // ctx.globalCompositeOperation = erasing
        //     ? "destination-out"
        //     : "source-over";
        // ctx.drawImage(strokeCanvas, 0, 0, width, height);
        
        strokeCtx.clearRect(0, 0, strokeWidth, strokeHeight);
        grayStrokeCtx.clearRect(0, 0, strokeWidth, strokeHeight);
        previewCtx.clearRect(0, 0, width, height)
        canvasChanged();
        // ctx.globalCompositeOperation = 'source-over'
    }

    function onMouseDown() {
        grayStrokeCtx.fillStyle = 'white'
        grayStrokeCtx.fillRect(0, 0, strokeWidth, strokeHeight)
        mouseDown = true
    }

    function onMouseMove(event) {
        if (!mouseDown) {
            return
        }
        const offsets = mouseOrTouchOffsets(event)
        
        if (offsets.length != 1) {
            // If using two fingers do no not draw
            return
        }
        const [x, y] = offsets[0]
        
        currentStroke.push([ x * strokeCanvasScale, y * strokeCanvasScale ])
        
        if (currentStroke.length > 2) {
            const last = currentStroke[currentStroke.length -2]
            // console.time('drawBrushLine')
            drawBrushLine(grayStrokeCtx, brushCanvas, last[0], last[1], x, y)
            // console.timeEnd('drawBrushLine')
            
            let startX = Math.min(last[0], x) - brushCanvas.width / 2
            let startY = Math.min(last[1], y) - brushCanvas.height / 2
            let dwidth = Math.abs(last[0] - x) + brushCanvas.width
            let dheight = Math.abs(last[1] - y) + brushCanvas.height

            // console.time('imageData')
            const imageData = grayStrokeCtx.getImageData(startX, startY, dwidth, dheight)
            for (let i = 0; i < imageData.data.length; i+=4) {
                imageData.data[i+3] = clamp((1 - opacity) * (255 - imageData.data[i]), 0, 255)
                imageData.data[i] = strokeColorRGB.r
                imageData.data[i+1] = strokeColorRGB.g
                imageData.data[i+2] = strokeColorRGB.b
            }
            strokeCtx.putImageData(imageData, startX, startY)
            // console.timeEnd('imageData')
        }

        previewCtx.clearRect(0, 0, canvas.width, canvas.height);
        previewCtx.drawImage(canvas, 0, 0);
        previewCtx.globalCompositeOperation = erasing
            ? "destination-out"
            : "source-over";
        previewCtx.drawImage(strokeCanvas, 0, 0, width, height);
        dispatch("stroke", {
            ctx: previewCtx,
            canvas: previewCanvas,
        });
        previewCtx.globalCompositeOperation = "source-over"; // reset.
    }
</script>

<div
    {id}
    class="canvasesContainer"
    style="cursor:{showCursor ? 'initial' : 'none'};{style}"
    on:mousedown={onMouseDown}
    on:touchstart|preventDefault={onMouseDown}
    on:mouseup={() => strokeDone()}
    on:touchend={() => strokeDone()}

    on:mouseleave={() => strokeDone()}
    on:touchcancel={() => strokeDone()}

    on:mousemove={onMouseMove}
    on:touchmove={onMouseMove}
    
>
    <canvas id="drawCanvas" bind:this={canvas} {width} {height} />
    <canvas
        style="width:{width}px;height:{height}px;"
        id="strokeCanvas"
        bind:this={strokeCanvas}
        width={strokeWidth}
        height={strokeHeight}
    />
</div>

<style>
    .canvasesContainer {
        position: relative;
    }
    #strokeCanvas {
        position: absolute;
        top: 0px;
        left: 0px;
    }
</style>
