<script lang="ts">
    import Artwork from "@/components/gallery/Artwork.svelte";
    import { post, postJSON } from "@/utils/network";
    import { onMount } from "svelte";
    import config from "@/config";
    import type { ArtworkData } from "@/types";
    let password:null | string = localStorage.getItem('password')
    let arworkData: ArtworkData[] = [];
    onMount(async () => {        
        if (password == null) {
            password = prompt('please enter the password') 
        }
        arworkData = await postJSON(`${config.apiURL}/get_artworks_for_review`, { password })
        if (arworkData.length) {
            // If results the password was correct and we can save it.
            localStorage.setItem('password', password)
        }
    });
    async function setAccepted(key: string, accepted: boolean) {
        await post(`${config.apiURL}/set_review_status`, { key, accepted, password })
        arworkData = arworkData.filter(a => a.key != key)
    }
</script>

<h1>Gallery</h1>
<div class="gallery">
    {#if arworkData.length == 0}
        <h2>Nothing to review.</h2>
    {/if}
    {#each arworkData as data}
        <Artwork
            key={data.key}
            artistName={data.artist_name}
            creationDate={new Date(data.created_at)}
        />
        <div class='buttons'>
            <button on:click={() => setAccepted(data.key, true)}>Accept</button>
            <button on:click={() => setAccepted(data.key, false)}>Reject</button>
        </div>
        <hr style='width:100%'>
    {/each}
</div>

<style>
    h1 {
        font-weight: 300;
        text-align: center;
    }
    .gallery {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
